import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';
import CatalogLayout from '../../layouts/catalog';

const LinkContainer = tw(
  Link,
)`flex flex-col mb-3 px-4 py-2  md:mb-6 md:px-8 py-4 hover:bg-purple-50`;
const Action = tw.img`mb-6 w-8 h-8 hidden md:block`;
const Title = tw.div`text-base md:text-lg text-black font-bold`;
const Excerpt = tw.div`py-2 text-sm md:text-base text-gray-600 text-justify`;
const Supplyment = tw.div`flex justify-between text-gray-400`;

export default () => {
  const data = useStaticQuery(graphql`
    query IndexQuery {
      allMarkdownRemark(
        filter: { frontmatter: { open: { eq: true } } }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        edges {
          node {
            excerpt
            frontmatter {
              author
              classify
              date(formatString: "YYYY 年 MM 月 DD 日")
              slug
              title
            }
            id
            timeToRead
            wordCount {
              words
            }
          }
        }
        totalCount
      }
    }
  `);

  return (
    <CatalogLayout title="博客">
      <Link to="/rss.xml">
        <Action alt="RSS" src="/blog/rss.svg" />
      </Link>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <LinkContainer key={node.id} to={node.frontmatter.slug}>
          <Title>{node.frontmatter.title}</Title>
          <Excerpt>{node.excerpt}</Excerpt>
          <Supplyment>
            <span>
              {node.frontmatter.classify}，{node.frontmatter.date}
            </span>
            <span>{node.frontmatter.author}</span>
          </Supplyment>
        </LinkContainer>
      ))}
    </CatalogLayout>
  );
};
